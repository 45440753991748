import React from "react";
import "../css/carousel.css";
import { useState } from "react";
import ProjectTab from "./ProjectTab";
import { MdOutlineArrowBackIosNew } from "react-icons/md";
import { MdArrowForwardIos } from "react-icons/md";

import { getCategoryProjects } from "../../Database/batchGetProjects.ts";

const Carousel = ({ name, setProj, statePV, setPV, setIsUserOwned }) => {
  const [maxIndex, setMaxIndex] = useState(0);
  /*work on this^*/

  const [index, setIndex] = useState(0);

  const [loading, setLoading] = useState(false);

  const [animation, setAnimation] = useState(0);

  const [query, setQuery] = useState(false);

  const [projects, setProjects] = useState([]);
  /*work on this^*/

  /*query for tag projects*/

  if (!query) {
    let ret;
    setLoading(true);

    const getProjects = async () => {
      ret = await getCategoryProjects([name]);
      if (ret.length != 0) {
        setProjects(ret);
        setMaxIndex(ret.length);
      }
      console.log(ret.length);
    };

    getProjects();
    setQuery(true);
    setLoading(false);
  }

  let carouselAnimated;

  const updateIndexUp = () => {
    if (index >= 0) setIndex(index);
    else setIndex(maxIndex + index);

    if (index < 6) setIndex(index + 1);
    else setIndex(0);
    setAnimation(1);
  };

  const updateIndexDown = () => {
    if (index > 0) setIndex(index - 1);
    else setIndex(maxIndex + index - 1);
    setAnimation(-1);
  };

  if (maxIndex == 0) {
    if (loading) {
      carouselAnimated = (
        <>
          <div id="empty">Loading some great projects...</div>
        </>
      );
    } else {
      carouselAnimated = (
        <>
          <div id="empty">No projects found... but you can change that.</div>
        </>
      );
    }
  } else if (animation == 0) {
    let incr = 0;
    if (maxIndex != 0) {
      if (maxIndex > 5) {
        carouselAnimated = (
          <>
            <button className="left" onClick={updateIndexDown}>
              <MdOutlineArrowBackIosNew />
            </button>
            {projects.map((proj) => {
              if (incr < 5)
                return (
                  <ProjectTab
                    Proj={projects[(index + incr++) % maxIndex]}
                    setProj={setProj}
                    statePV={statePV}
                    setPV={setPV}
                    setIsUserOwned={setIsUserOwned}
                  />
                );
            })}

            <button className="right" onClick={updateIndexUp}>
              <MdArrowForwardIos />
            </button>
          </>
        );
      } else {
        carouselAnimated = (
          <>
            <button className="left1" style={{ opacity: 0 }}>
              <MdOutlineArrowBackIosNew />
            </button>
            {projects.map((proj) => {
              if (incr < 5)
                return (
                  <ProjectTab
                    Proj={projects[(index + incr++) % maxIndex]}
                    setProj={setProj}
                    statePV={statePV}
                    setPV={setPV}
                    setIsUserOwned={setIsUserOwned}
                  />
                );
            })}
            <button className="right" style={{ opacity: 0 }}>
              <MdOutlineArrowBackIosNew />
            </button>
          </>
        );
      }
    } else carouselAnimated = <></>;
  } else if (animation == -1) {
    let index1 = index + 1;
    let incr = 0;
    let done = false;
    carouselAnimated = (
      <>
        <button className="left" onClick={updateIndexDown}>
          <MdOutlineArrowBackIosNew />
        </button>

        <ProjectTab
          Proj={projects[(index1 + maxIndex - 1) % maxIndex]}
          id="slidein"
        />

        {projects.map((proj) => {
          if (!done && incr < maxIndex - 1 && incr < 4)
            return <ProjectTab Proj={projects[(index1 + incr++) % maxIndex]} />;
          else if ((!done && incr == maxIndex - 1) || incr == 4) {
            done = true;
            return (
              <ProjectTab
                Proj={projects[(index1 + incr++) % maxIndex]}
                id="slideout"
              />
            );
          }
        })}

        <button className="right" onClick={updateIndexUp}>
          <MdArrowForwardIos />
        </button>
      </>
    );
    setTimeout(() => {
      setAnimation(0);
    }, 200);
  } else if (animation == 1) {
    let index1 = index;

    carouselAnimated = (
      <>
        <button className="left" onClick={updateIndexDown}>
          <MdOutlineArrowBackIosNew />
        </button>

        <ProjectTab
          Proj={projects[(index1 + maxIndex - 1) % maxIndex]}
          id="slideout"
        />
        <ProjectTab Proj={projects[index1 % maxIndex]} />
        <ProjectTab Proj={projects[(index1 + 1) % maxIndex]} />
        <ProjectTab Proj={projects[(index1 + 2) % maxIndex]} />
        <ProjectTab Proj={projects[(index1 + 3) % maxIndex]} />
        <ProjectTab Proj={projects[(index1 + 4) % maxIndex]} id="slidein" />

        <button className="right" onClick={updateIndexUp}>
          <MdArrowForwardIos />
        </button>
      </>
    );
    setTimeout(() => {
      setAnimation(0);
    }, 200);
  }

  return (
    <>
      <div className="carouselName">{name}</div>
      <div className="carousel">{carouselAnimated}</div>
    </>
  );
};

export default Carousel;

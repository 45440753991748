"use strict";
exports.__esModule = true;
exports.sendAcceptEmail = void 0;
var mail = require("@sendgrid/mail");
function sendAcceptEmail(recieverEmail, username, projectOwnerEmail) {
    // validation
    if (recieverEmail === undefined) {
        throw Error("Reciever email is undefined. Please pass in a valid email");
    }
    if (projectOwnerEmail === undefined) {
        throw Error("Project owner email is undefined. Please pass in a valid email");
    }
    if (username === undefined) {
        throw Error("Username is undefined. Please pass in a valid username");
    }
    // Connect
    var APIKey = "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";
    mail.setApiKey(APIKey);
    // Send
    var msg = {
        to: recieverEmail,
        from: "12thdev@gmail.com",
        subject: "Application Accepted",
        text: "Congtrats! Your project application was accepted. Please reach out to the project owner at " + projectOwnerEmail + " for your next steps",
        html: "<h3>Congratulations!</h3><br />Your project application was accepted!. Please reach out to the project owner at " + projectOwnerEmail + " for your next steps"
    };
    mail
        .send(msg)
        .then(function () {
        console.log("Email sent");
    })["catch"](function (error) {
        console.error(error);
    });
}
exports.sendAcceptEmail = sendAcceptEmail;
sendAcceptEmail("regen8@tamu.edu", "regen", "braeden_fleeman@tamu.edu");

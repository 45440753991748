import Header from "./Header";
import Body from "./Body";
import CreateProjectModal from "./CreateProjectModal";
import ProjectViewerModal from "../CommonComponents/ProjectViewerModal";
import InboxModal from "./InboxModal";
import UserProfile from "./UserProfile";
import "../css/app.css";
import { useState } from "react";

const UserPage = ({
  page,
  userEmail,
  setPage,
  userID,
  setContrastMode,
  contrastMode,
}) => {
  const [toggle, setToggle] = useState(false);
  const [visibleCP, setVisibleCP] = useState(false);
  const [visiblePV, setVisiblePV] = useState(false);
  const [visibleUP, setVisibleUP] = useState(false);
  const [visibleInbox, setVisibleInbox] = useState(false);

  const [searching, setSearching] = useState(false);
  const [searchString, setSS] = useState("");

  const [isUserOwned, setIsUserOwned] = useState(false);

  const [reload, setReload] = useState(false);

  const [project, setProject] = useState({
    projectName: "",
    projectOwnerId: "",
    description: "",
    completionPercentage: 0,
    members: [],
    tags: [],
    requirements: [],
    createdOn: "",
    lastUpdated: "",
    status: "",
  });

  const [tags, setTags] = useState([
    { id: "Bioinformatics", set: false },
    { id: "Cybersecurity", set: false },
    { id: "Databases", set: false },
    { id: "Digital Humanities", set: false },
    { id: "Gaming", set: false },
    { id: "Human-Computer Interactions", set: false },
    { id: "Machine Learning", set: false },
    { id: "Mobile Development", set: false },
    { id: "Networking", set: false },
    { id: "Robotics", set: false },
    { id: "Visualization", set: false },
    { id: "Web Development", set: false },
    { id: "Other", set: false },
  ]);

  const disableScroll = () => {
    document.body.style.height = "100%";
    document.body.style.overflow = "hidden";
  };

  const enableScroll = () => {
    document.body.style.height = "auto";
    document.body.style.overflow = "unset";
  };
  const handleScroll = () => {
    if (visibleCP || visiblePV || visibleUP) {
      disableScroll();
    } else {
      enableScroll();
    }
  };

  const changeTagState = (id, set) => {
    setTags(
      tags.map((tag) => {
        if (id === tag.id) {
          return { id: tag.id, set: set };
        } else return { id: tag.id, set: tag.set };
      })
    );
  };

  handleScroll();

  let CPM = (
    <CreateProjectModal
      state={visibleCP}
      changeVis={setVisibleCP}
      userID={userID}
    />
  );

  return (
    <div className="App">
      <Header
        setSS={setSS}
        setSearching={setSearching}
        state={toggle}
        changer={setToggle}
        projectTags={tags}
        tagChange={changeTagState}
        createModalState={visibleCP}
        createModalVis={setVisibleCP}
        userProfileState={visibleUP}
        userProfileVis={setVisibleUP}
        inboxState={visibleInbox}
        inboxVis={setVisibleInbox}
        page={page}
        setPage={setPage}
        setContrastMode={setContrastMode}
        contrastMode={contrastMode}
      />
      <Body
        searching={searching}
        searchString={searchString}
        userID={userID}
        state={toggle}
        changer={setToggle}
        projectTags={tags}
        setProj={setProject}
        statePV={visiblePV}
        setPV={setVisiblePV}
        setIsUserOwned={setIsUserOwned}
      />
      {visibleCP && CPM}
      {visiblePV && (
        <ProjectViewerModal
          Proj={project}
          state={visiblePV}
          changeVis={setVisiblePV}
          page={page}
          isUserOwned={isUserOwned}
          userID={userID}
          userEmail={userEmail}
          setReload={setReload}
        />
      )}
      {visibleInbox && (
        <InboxModal
          userID={userID}
          state={visibleInbox}
          changeVis={setVisibleInbox}
        />
      )}
      {visibleUP && (
        <UserProfile
          userID={userID}
          state={visibleUP}
          changeVis={setVisibleUP}
        />
      )}
    </div>
  );
};

export default UserPage;

import { v4 } from "uuid";
import { getUserByEmail, getUserByUserName} from "../Database/getUser";
import { storeUser } from "../Database/storeUser";
import { User } from "../Schema/User";
import {
  validateEmail,
  validateUserName,
  validatePassword,
  validateFirstName,
  validateLastName,
} from "./validateUserFields";

async function createUser(
  first_Name: string,
  last_Name: string,
  user_Name: string,
  _email: string,
  _password: string,
  _interests: string[],
  _qualifications: string[]
): Promise<boolean> {
  // Input Validation
  if (!(await validateEmail(_email))) {
    return false;
  }
  if (!validatePassword(_password)) {
    return false;
  }
  if (!(await validateUserName(user_Name))) {
    return false;
  }
  if (!validateFirstName(first_Name)) {
    return false;
  }
  if (!validateLastName(last_Name)) {
    return false;
  }

  const userCheck: User = await getUserByEmail(_email);
  if (userCheck.userName !== "") {
    console.log("Email address already has account");
    return false;
  }

  const userCheck2: User = await getUserByUserName(user_Name);
  if (userCheck2.userName !== "") {
    console.log("Entered username has already been taken.");
    return false;
  }

  // Create user object
  const newUser: User = {
    userId: v4(),
    userName: user_Name,
    password: _password,
    firstName: first_Name,
    lastName: last_Name,
    email: _email,
    interests: _interests,
    qualifications: _qualifications,
    numHosting: 0,
    numJoined: 0,
    avatar: "no avatar",
  };

  // Store in database

  try {
    await storeUser(newUser);
    console.log("User successfully created and stored.");
  } catch (e) {
    console.log("Error Occurred During User Creation");
    return false;
  }
  return true;
}

export { createUser };

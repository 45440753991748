import React from "react";
import "../css/modal.css";
import { motion, useAnimation } from "framer-motion";
import { useState } from "react";
import Login from "./Login";

const LoginModal = ({ setUserID, setUserEmail, state, changeVis, setPage }) => {
  const [newUser, setNewUser] = useState(false);
  const show = {
    visiblility: "visible",
  };

  const show1 = {
    visiblility: "visible",
    scale: 1,
  };

  return (
    <motion.div className="baseModal" animate={show}>
      <span className="close" onClick={() => changeVis(!state)}>
        &times;
      </span>
      <motion.div className={newUser ? "frameModal" : "loginModalFrame"}>
        <motion.div
          className={newUser ? "modal" : "loginModal"}
          animate={show1}
          initial={{
            scale: 0.5,
          }}
        >
          <Login
            setUserID={setUserID}
            setUserEmail={setUserEmail}
            state={state}
            changeVis={changeVis}
            setPage={setPage}
            newUser={newUser}
            setNewUser={setNewUser}
          />
        </motion.div>
      </motion.div>
    </motion.div>
  );
};

export default LoginModal;

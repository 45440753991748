"use strict";
exports.__esModule = true;
exports.db = void 0;
// Import the functions you need from the SDKs you need
// import { initializeApp, cert } from "firebase-admin/app";
var app_1 = require("firebase/app");
// import { getAnalytics } from "firebase/analytics";
var firestore_1 = require("firebase/firestore");
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries
// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
var firebaseConfig = {
    apiKey: "AIzaSyABX31M8K3Dcc9f8mne_QByTmS1XoJAQ_E",
    authDomain: "th-dev-a24cd.firebaseapp.com",
    projectId: "th-dev-a24cd",
    storageBucket: "th-dev-a24cd.appspot.com",
    messagingSenderId: "181605512291",
    appId: "1:181605512291:web:514ff467424d0c77417456",
    measurementId: "G-8QEP69BVHG"
};
// Initialize Firebase
// const serviceAccount = "src/Database/private-key.json";
var app = (0, app_1.initializeApp)(firebaseConfig);
var db = (0, firestore_1.getFirestore)(app);
exports.db = db;

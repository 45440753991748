"use strict";
exports.__esModule = true;
exports.sendDenialEmail = void 0;
var mail = require("@sendgrid/mail");
function sendDenialEmail(recieverEmail, username, projectOwnerEmail) {
    // validation
    if (recieverEmail === undefined) {
        throw Error("Reciever email is undefined. Please pass in a valid email");
    }
    if (projectOwnerEmail === undefined) {
        throw Error("Project owner email is undefined. Please pass in a valid email");
    }
    if (username === undefined) {
        throw Error("Username is undefined. Please pass in a valid username");
    }
    // Connect
    var APIKey = "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";
    mail.setApiKey(APIKey);
    // Send
    var msg = {
        to: recieverEmail,
        from: "12thdev@gmail.com",
        subject: "Application Denied",
        html: "<h3>Your application has been denied</h3><br/>\n    Your project application was rejected. Please reach out to the project owner at " + projectOwnerEmail + " for more information\n    on why or apply to another project."
    };
    mail
        .send(msg)
        .then(function () {
        console.log("Email sent");
    })["catch"](function (error) {
        console.error(error);
    });
}
exports.sendDenialEmail = sendDenialEmail;

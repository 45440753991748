import { getUserByEmail } from "../Database/getUser";
import { User } from "../Schema/User";

//I'm thinking of having the function return the userID string instead so that we can assigned login users their IDs during the session.

async function login(_email: string, _password: string): Promise<boolean> {
  const retrieved: User = await getUserByEmail(_email);
  if (retrieved.password === "") {
    //no existing user in databse
    console.log("No existing user by that email in database");
    return false;
  } else if (retrieved.password !== _password) {
    console.log("Incorrect password");
    return false;
  } else if (retrieved.password === _password) {
    console.log("Access granted");
    return true;
  }
  return false;
}

//testing functions
// login("helpMe@tamu.edu", "0825");
// login("helpMe@tamu.edu", "082");
// login("helle@tamu.edu", "0825");

export { login };

"use strict";
var __awaiter =
  (this && this.__awaiter) ||
  function (thisArg, _arguments, P, generator) {
    function adopt(value) {
      return value instanceof P
        ? value
        : new P(function (resolve) {
            resolve(value);
          });
    }
    return new (P || (P = Promise))(function (resolve, reject) {
      function fulfilled(value) {
        try {
          step(generator.next(value));
        } catch (e) {
          reject(e);
        }
      }
      function rejected(value) {
        try {
          step(generator["throw"](value));
        } catch (e) {
          reject(e);
        }
      }
      function step(result) {
        result.done
          ? resolve(result.value)
          : adopt(result.value).then(fulfilled, rejected);
      }
      step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
  };
var __generator =
  (this && this.__generator) ||
  function (thisArg, body) {
    var _ = {
        label: 0,
        sent: function () {
          if (t[0] & 1) throw t[1];
          return t[1];
        },
        trys: [],
        ops: [],
      },
      f,
      y,
      t,
      g;
    return (
      (g = { next: verb(0), throw: verb(1), return: verb(2) }),
      typeof Symbol === "function" &&
        (g[Symbol.iterator] = function () {
          return this;
        }),
      g
    );
    function verb(n) {
      return function (v) {
        return step([n, v]);
      };
    }
    function step(op) {
      if (f) throw new TypeError("Generator is already executing.");
      while (_)
        try {
          if (
            ((f = 1),
            y &&
              (t =
                op[0] & 2
                  ? y["return"]
                  : op[0]
                  ? y["throw"] || ((t = y["return"]) && t.call(y), 0)
                  : y.next) &&
              !(t = t.call(y, op[1])).done)
          )
            return t;
          if (((y = 0), t)) op = [op[0] & 2, t.value];
          switch (op[0]) {
            case 0:
            case 1:
              t = op;
              break;
            case 4:
              _.label++;
              return { value: op[1], done: false };
            case 5:
              _.label++;
              y = op[1];
              op = [0];
              continue;
            case 7:
              op = _.ops.pop();
              _.trys.pop();
              continue;
            default:
              if (
                !((t = _.trys), (t = t.length > 0 && t[t.length - 1])) &&
                (op[0] === 6 || op[0] === 2)
              ) {
                _ = 0;
                continue;
              }
              if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) {
                _.label = op[1];
                break;
              }
              if (op[0] === 6 && _.label < t[1]) {
                _.label = t[1];
                t = op;
                break;
              }
              if (t && _.label < t[2]) {
                _.label = t[2];
                _.ops.push(op);
                break;
              }
              if (t[2]) _.ops.pop();
              _.trys.pop();
              continue;
          }
          op = body.call(thisArg, _);
        } catch (e) {
          op = [6, e];
          y = 0;
        } finally {
          f = t = 0;
        }
      if (op[0] & 5) throw op[1];
      return { value: op[0] ? op[1] : void 0, done: true };
    }
  };
exports.__esModule = true;
exports.validateUserName =
  exports.validatePassword =
  exports.validateLastName =
  exports.validateFirstName =
  exports.validateEmail =
    void 0;
var containsProfanity_1 = require("../Utility/containsProfanity");
var verifyEmail_1 = require("../Utility/verifyEmail");
// Usernames are not case sensitive, must be between 8 and 60 characters long,
// and may only contain letters, numbers, and the following special characters:
// period (.), at symbol (@), underscore (_), and hyphen (-). Usernames cannot
// contain spaces. special characters (such as !, $, #, %)
function validateUserName(_username) {
  return __awaiter(this, void 0, void 0, function () {
    var regex;
    return __generator(this, function (_a) {
      switch (_a.label) {
        case 0:
          regex = /^[A-Za-z0-9._@-]+$/;
          if (!_username.match(regex)) {
            console.log(
              "Invalid username entered. Can only have letters, numbers, and the characters . - _ @"
            );
            return [2 /*return*/, false];
          }
          if (_username.length < 6 || _username.length > 60) {
            console.log(
              "Username should be at least 6 characters long and at most 60 characters"
            );
            return [2 /*return*/, false];
          }
          return [
            4 /*yield*/,
            containsProfanity_1.containsProfanity(_username),
          ];
        case 1:
          if (_a.sent()) {
            console.log("Username contains profanity. Please remove it");
            return [2 /*return*/, false];
          }
          console.log("Username validated");
          return [2 /*return*/, true];
      }
    });
  });
}
exports.validateUserName = validateUserName;
function validateEmail(_email) {
  return __awaiter(this, void 0, void 0, function () {
    var regex;
    return __generator(this, function (_a) {
      switch (_a.label) {
        case 0:
          regex = /^[A-Za-z0-9._-]+@tamu\.edu$/;
          if (!_email.match(regex)) {
            console.log(
              "Email can only have letters, numbers, and characters . - _ and it must end with @tamu.edu"
            );
            return [2 /*return*/, false];
          }
          if (_email.length > 320) {
            console.log("Email cannot be over 320 characters long");
            return [2 /*return*/, false];
          }
          return [4 /*yield*/, verifyEmail_1.verifyEmail(_email)];
        case 1:
          if (!_a.sent()) {
            console.log("Email failed verification");
            return [2 /*return*/, false];
          }
          console.log("Email validated");
          return [2 /*return*/, true];
      }
    });
  });
}
exports.validateEmail = validateEmail;
function validatePassword(_password) {
  if (_password.length < 6) {
    console.log("Password must be at least 6 characters long");
    return false;
  }
  console.log("Password validated");
  return true;
}
exports.validatePassword = validatePassword;
function validateFirstName(_firstname) {
  return __awaiter(this, void 0, void 0, function () {
    return __generator(this, function (_a) {
      switch (_a.label) {
        case 0:
          if (!(_firstname.length === 0)) return [3 /*break*/, 1];
          console.log("Blank firstname cannot be accepted");
          return [2 /*return*/, false];
        case 1:
          if (!(_firstname.length > 747)) return [3 /*break*/, 2];
          console.log("Firstname cannot be over 747 characters long");
          return [2 /*return*/, false];
        case 2:
          return [
            4 /*yield*/,
            containsProfanity_1.containsProfanity(_firstname),
          ];
        case 3:
          if (_a.sent()) {
            console.log("First name contains profanity. Please remove it");
            return [2 /*return*/, false];
          }
          _a.label = 4;
        case 4:
          console.log("Firstname validated");
          return [2 /*return*/, true];
      }
    });
  });
}
exports.validateFirstName = validateFirstName;
function validateLastName(_lastname) {
  return __awaiter(this, void 0, void 0, function () {
    return __generator(this, function (_a) {
      switch (_a.label) {
        case 0:
          if (!(_lastname.length === 0)) return [3 /*break*/, 1];
          console.log("Blank lastname cannot be accepted");
          return [2 /*return*/, false];
        case 1:
          if (!(_lastname.length > 747)) return [3 /*break*/, 2];
          console.log("Lastname cannot be over 747 characters long");
          return [2 /*return*/, false];
        case 2:
          return [
            4 /*yield*/,
            containsProfanity_1.containsProfanity(_lastname),
          ];
        case 3:
          if (_a.sent()) {
            console.log("Last name contains profanity. Please remove it");
            return [2 /*return*/, false];
          }
          _a.label = 4;
        case 4:
          console.log("Lastname validated");
          return [2 /*return*/, true];
      }
    });
  });
}
exports.validateLastName = validateLastName;

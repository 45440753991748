import { v4, validate } from "uuid";
import { getUserById } from "../Database/getUser";
import { storeProject } from "../Database/storeProject";
import { storeUser } from "../Database/storeUser";
import { Project, ProjectStatus } from "../Schema/Project";
import { User } from "../Schema/User";
import { isProjectDuplicate } from "./isProjectDuplicate";
import { containsProfanity } from "../Utility/containsProfanity";

async function createProject(
  name: string,
  ownerId: string,
  projectDescription: string,
  descriptorTags?: string[],
  prequisites?: string[],
  completionAmount?: number
) {
  const user: User = await getUserById(ownerId);

  // Input Validation
  if (!validate(ownerId)) {
    throw Error(`OwnerID ${ownerId} is Invalid`);
  }

  if (await containsProfanity(name)) {
    throw Error("Title Contains Profanity. Please Remove It.");
  }

  if (await containsProfanity(projectDescription)) {
    throw Error("Description Contains Profanity. Please Remove It.");
  }

  if (user.numHosting > 5) {
    throw Error("Already hosting 5 projects. Cannot create anymore");
  }

  // Create project object
  const newProject: Project = {
    projectId: v4(),
    projectName: name,
    projectOwnerId: ownerId,
    description: projectDescription,
    completionPercentage: completionAmount !== undefined ? completionAmount : 0,
    tags: descriptorTags ? descriptorTags : ["Other"],
    members: [],
    requirements: prequisites ? prequisites : [],
    createdOn: new Date(),
    lastUpdated: new Date(),
    status: ProjectStatus.OPEN,
  };

  if (
    await isProjectDuplicate(name, projectDescription, newProject.projectId)
  ) {
    throw Error("Project is a Duplicate. Please change title or description");
  }

  // Store in database
  try {
    // Update user hosting
    user.numHosting++;
    storeUser(user);

    await storeProject(newProject);
  } catch (e) {
    console.log("Error Occurred During Project Creation");
  }

  return newProject;
}

export { createProject };

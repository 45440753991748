import React from "react";
import "../css/projecttab.css";
import { useState } from "react";

const ProjectTab = ({
  label,
  id,
  Proj,
  setProj,
  statePV,
  setPV,
  setIsUserOwned,
}) => {
  return (
    <div
      className="projecttab"
      id={id}
      onClick={() => {
        setPV(!statePV);
        setProj(Proj);
        setIsUserOwned(false);
      }}
    >
      <div className="projectimage"></div>
      <div className="projectmessage">
        <h4>{Proj.projectName}</h4>
      </div>
    </div>
  );
};

export default ProjectTab;

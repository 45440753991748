import { collection, getDocs, query, where } from "@firebase/firestore";
import { db } from "./getDB";
import { Project } from "../Schema/Project";
import { User } from "../Schema/User";

async function getCategoryProjects(tags: string[]): Promise<Project[]> {
  const projects: Project[] = [];

  try {
    const projectCollection = collection(db, "Projects");
    for await (const tag of tags) {
      const tagQuery = query(
        projectCollection,
        where("tags", "array-contains", tag)
      );
      const queryResults = await getDocs(tagQuery);
      if (!queryResults.empty) {
        await queryResults.forEach((doc) => {
          if (!projects.includes(doc.data() as Project)) {
            projects.push(doc.data() as Project);
          }
        });
      }
    }
  } catch (e) {
    console.log(
      `${e} Occurred While Querying For The Following Project Tags: ${tags}`
    );
    throw e;
  }

  return projects;
}

async function getProjectsOwned(userId: string): Promise<Project[]> {
  const projects: Project[] = [];

  try {
    const projectCollection = collection(db, "Projects");
    const userQuery = query(
      projectCollection,
      where("projectOwnerId", "==", userId)
    );
    const queryResults = await getDocs(userQuery);
    if (!queryResults.empty) {
      queryResults.forEach((doc) => {
        projects.push(doc.data() as Project);
      });
    }
  } catch (e) {
    console.log(
      `${e} Occurred While Querying for User:${userId} Owned Project's`
    );
    throw e;
  }

  return projects;
}

async function getAllUserProjects(userId: string) {
  // Get Projects user created
  const projects: Project[] = await getProjectsOwned(userId);

  // Get projects user is a part of
  const projectCollection = collection(db, "Projects");
  const userQuery = query(
    projectCollection,
    where("members", "array-contains", userId)
  );
  const queryResults = await getDocs(userQuery);
  if (!queryResults.empty) {
    queryResults.forEach((doc) => {
      projects.push(doc.data() as Project);
    });
  }

  return projects;
}

async function getAllUserCompletedProjects(userId: string) {
  // Get Projects user created and are completed
  const projects: Project[] = [];
  const projectCollection = collection(db, "Projects");
  const ownerQuery = query(
    projectCollection,
    where("projectOwnerId", "==", userId),
    where("status", "==", "FINISHED")
  );
  let queryResults = await getDocs(ownerQuery);
  if (!queryResults.empty) {
    queryResults.forEach((doc) => {
      projects.push(doc.data() as Project);
    });
  }

  // Get projects user is a part of and are completed
  const userQuery = query(
    projectCollection,
    where("members", "array-contains", userId),
    where("status", "==", "FINISHED")
  );
  queryResults = await getDocs(userQuery);
  if (!queryResults.empty) {
    queryResults.forEach((doc) => {
      projects.push(doc.data() as Project);
    });
  }

  return projects;
}

async function getProjectsByUsername(username: string): Promise<Project[]> {
  const projects: Project[] = [];
  const userIds: string[] = [];

  // Get users
  const docCollection = collection(db, "Users");
  const userQuery = query(docCollection, where("userName", "==", username));
  const queryResults = await getDocs(userQuery);
  console.log(queryResults.empty);
  if (!queryResults.empty) {
    await queryResults.forEach(async (doc) => {
      const user = doc.data() as User;
      userIds.push(user.userId);
    });

    // Get Projects
    for await (const id of userIds) {
      const userProjects = await getProjectsOwned(id);
      await userProjects.forEach((project) => {
        projects.push(project);
      });
    }
  }

  return projects;
}

export {
  getCategoryProjects,
  getProjectsOwned,
  getProjectsByUsername,
  getAllUserProjects,
  getAllUserCompletedProjects,
};

import React from "react";
import "../css/modal.css";
import { motion, useAnimation } from "framer-motion";
import { useState } from "react";

import CreateProject from "./CreateProject";

const CreateProjectModal = ({ userID, state, changeVis, tags, setTags }) => {
  const show = {
    visiblility: "visible",
  };

  const show1 = {
    visiblility: "visible",
    scale: 1,
  };

  return (
    <motion.div className="baseModal" animate={show}>
      <span className="close" onClick={() => changeVis(!state)}>
        &times;
      </span>
      <motion.div className="frameModal">
        <motion.div
          className="modal"
          animate={show1}
          initial={{
            scale: 0.5,
          }}
        >
          <CreateProject
            state={state}
            changeVis={changeVis}
            tags={tags}
            setTags={setTags}
            userID={userID}
          />
        </motion.div>
      </motion.div>
    </motion.div>
  );
};

export default CreateProjectModal;

import React from "react";
import "../css/projecttab.css";

const UserProjectTab = ({
  label,
  id,
  Proj,
  setProj,
  statePV,
  setPV,
  setIsUserOwned,
  userID,
}) => {
  console.log(userID == Proj.projectOwnerId);
  return (
    <div
      className="projecttab"
      id={id}
      onClick={() => {
        setPV(!statePV);
        setProj(Proj);
        setIsUserOwned(userID == Proj.projectOwnerId);
      }}
    >
      <div className="projectimage"></div>
      <div className="projectmessage">
        <h4>{Proj.projectName}</h4>
      </div>
    </div>
  );
};

export default UserProjectTab;

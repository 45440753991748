import React from "react";
import "../css/carousel.css";
import { useState } from "react";
import UserProjectTab from "./UserProjectTab";
import { MdOutlineArrowBackIosNew } from "react-icons/md";
import { MdArrowForwardIos } from "react-icons/md";

import { getAllUserProjects } from "../../Database/batchGetProjects.ts";
import { getProjectsByUsername } from "../../Database/batchGetProjects.ts";

const UserCarousel = ({
  onID,
  name,
  userID,
  setProj,
  statePV,
  setPV,
  setIsUserOwned,
  completed,
}) => {
  const [maxIndex, setMaxIndex] = useState(0);
  /*work on this^*/

  const [index, setIndex] = useState(0);

  const [loading, setLoading] = useState(false);

  const [animation, setAnimation] = useState(0);

  const [query, setQuery] = useState(false);

  const [projects, setProjects] = useState([]);
  /*work on this^*/

  /*query for tag projects*/

  let ret;
  const getProjects = async () => {
    if (onID) ret = await getAllUserProjects(userID);
    else {
      ret = await getProjectsByUsername(userID);
    }
    if (ret) {
      if (completed) {
        setProjects(ret.filter((item) => item.completionPercentage == 100));
        setMaxIndex(
          ret.filter((item) => item.completionPercentage == 100).length
        );
      } else {
        setProjects(ret.filter((item) => item.completionPercentage != 100));
        setMaxIndex(
          ret.filter((item) => item.completionPercentage != 100).length
        );
      }
    }
  };

  if (!query) {
    setLoading(true);
    getProjects();
    setQuery(true);
    setLoading(false);
  }

  let carouselAnimated;

  const updateIndexUp = () => {
    if (index >= 0) setIndex(index);
    else setIndex(maxIndex + index);

    if (index < 6) setIndex(index + 1);
    else setIndex(0);
    setAnimation(1);
  };

  const updateIndexDown = () => {
    if (index > 0) setIndex(index - 1);
    else setIndex(maxIndex + index - 1);
    setAnimation(-1);
  };

  if (maxIndex == 0) {
    if (loading) {
      carouselAnimated = (
        <>
          <div id="empty">Loading some great projects...</div>
        </>
      );
    } else if (onID) {
      carouselAnimated = (
        <>
          <div id="empty">No projects found... but you can change that.</div>
        </>
      );
    } else {
      carouselAnimated = (
        <>
          <div id="empty">
            This user has no projects at the moment or does not exist...
          </div>
        </>
      );
    }
  } else if (animation == 0) {
    let incr = 0;
    if (maxIndex != 0) {
      if (maxIndex > 5) {
        carouselAnimated = (
          <>
            <button className="left" onClick={updateIndexDown}>
              <MdOutlineArrowBackIosNew />
            </button>
            {projects.map((proj) => {
              if (incr < 5)
                return (
                  <UserProjectTab
                    Proj={projects[(index + incr++) % maxIndex]}
                    setProj={setProj}
                    statePV={statePV}
                    setPV={setPV}
                    setIsUserOwned={setIsUserOwned}
                    userID={userID}
                  />
                );
            })}

            <button className="right" onClick={updateIndexUp}>
              <MdArrowForwardIos />
            </button>
          </>
        );
      } else {
        carouselAnimated = (
          <>
            <button className="left" style={{ opacity: 0 }}>
              <MdOutlineArrowBackIosNew />
            </button>
            {projects.map((proj) => {
              if (incr < 5)
                return (
                  <UserProjectTab
                    Proj={projects[(index + incr++) % maxIndex]}
                    setProj={setProj}
                    statePV={statePV}
                    setPV={setPV}
                    setIsUserOwned={setIsUserOwned}
                    userID={userID}
                  />
                );
            })}
            <button className="right" style={{ opacity: 0 }}>
              <MdOutlineArrowBackIosNew />
            </button>
          </>
        );
      }
    } else carouselAnimated = <></>;
  } else if (animation == -1) {
    let index1 = index + 1;
    let incr = 0;
    let done = false;
    carouselAnimated = (
      <>
        <button className="left" onClick={updateIndexDown}>
          <MdOutlineArrowBackIosNew />
        </button>

        <UserProjectTab
          Proj={projects[(index1 + maxIndex - 1) % maxIndex]}
          id="slidein"
        />

        {projects.map((proj) => {
          if (!done && incr < maxIndex - 1 && incr < 4)
            return (
              <UserProjectTab Proj={projects[(index1 + incr++) % maxIndex]} />
            );
          else if ((!done && incr == maxIndex - 1) || incr == 4) {
            done = true;
            return (
              <UserProjectTab
                Proj={projects[(index1 + incr++) % maxIndex]}
                id="slideout"
              />
            );
          }
        })}

        <button className="right" onClick={updateIndexUp}>
          <MdArrowForwardIos />
        </button>
      </>
    );
    setTimeout(() => {
      setAnimation(0);
    }, 200);
  } else if (animation == 1) {
    let index1 = index;

    carouselAnimated = (
      <>
        <button className="left" onClick={updateIndexDown}>
          <MdOutlineArrowBackIosNew />
        </button>

        <UserProjectTab
          Proj={projects[(index1 + maxIndex - 1) % maxIndex]}
          id="slideout"
        />
        <UserProjectTab Proj={projects[index1 % maxIndex]} />
        <UserProjectTab Proj={projects[(index1 + 1) % maxIndex]} />
        <UserProjectTab Proj={projects[(index1 + 2) % maxIndex]} />
        <UserProjectTab Proj={projects[(index1 + 3) % maxIndex]} />
        <UserProjectTab Proj={projects[(index1 + 4) % maxIndex]} id="slidein" />

        <button className="right" onClick={updateIndexUp}>
          <MdArrowForwardIos />
        </button>
      </>
    );
    setTimeout(() => {
      setAnimation(0);
    }, 200);
  }

  return (
    <>
      <div className="carouselName">{name}</div>
      <div className="carousel">{carouselAnimated}</div>
    </>
  );
};

export default UserCarousel;

"use strict";
exports.__esModule = true;
exports.sendRequestEmail = void 0;
var mail = require("@sendgrid/mail");
function sendRequestEmail(recieverEmail, username) {
    // validation
    if (recieverEmail === undefined) {
        throw Error("Reciever email is undefined. Please pass in a valid email");
    }
    if (username === undefined) {
        throw Error("Username is undefined. Please pass in a valid username");
    }
    // Connect
    var APIKey = "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";
    mail.setApiKey(APIKey);
    // Send
    var msg = {
        to: recieverEmail,
        from: "12thdev@gmail.com",
        subject: "You have a project application request",
        html: "<h3>You have a new application request!</h3><br />Please login to see view this request."
    };
    mail
        .send(msg)
        .then(function () {
        console.log("Email sent");
    })["catch"](function (error) {
        console.error(error);
    });
}
exports.sendRequestEmail = sendRequestEmail;

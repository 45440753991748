import { doc, getDoc, query, collection, where, getDocs} from "@firebase/firestore";
import { db } from "../Database/getDB";
import { User } from "../Schema/User";

async function getUserById(userId: string): Promise<User> {
  let user: User = {
    userId: "",
    userName: "",
    password: "",
    firstName: "",
    lastName: "",
    email: "",
    interests: [],
    qualifications: [],
    numHosting: 0,
    numJoined: 0,
    avatar: "no avatar"
  };

  try {
    const docRef = await doc(db, "Users", userId);
    const snapshot = await getDoc(docRef);
    if (snapshot.exists()) {
      user = snapshot.data() as User;
    } else {
      console.log("User with the id not found");
      return user;
    }
  } catch (e) {
    console.error(
      `${e} Occurred During User Query for User:${userId}`
    );
    throw e;
  }

  return user;
}

async function getUserByEmail(_email: string): Promise<User> {
  let user: User = {
    userId: "",
    userName: "",
    password: "",
    firstName: "",
    lastName: "",
    email: "",
    interests: [],
    qualifications: [],
    numHosting: 0,
    numJoined: 0,
    avatar: "no avatar"
  };

  try {

    const q = query(collection(db, "Users"), where("email", "==", _email));

    const querySnapshot = await getDocs(q);
    
    if(querySnapshot.empty){
      console.log("User with the email not found");
      return user;
    } else {
      querySnapshot.forEach((doc) => {
        //console.log(doc.id, " => ", doc.data());
        if(doc.data())
        user = doc.data() as User;
      })

    } 
  }catch (e) {
    console.error(
      `${e} Occurred During User Query for User with email:${_email}`
    );
    throw e;
  }
  return user;
}

async function getUserByUserName(_userName: string): Promise<User> {
  let user: User = {
    userId: "",
    userName: "",
    password: "",
    firstName: "",
    lastName: "",
    email: "",
    interests: [],
    qualifications: [],
    numHosting: 0,
    numJoined: 0,
    avatar: "no avatar"
  };

  try {

    const q = query(collection(db, "Users"), where("userName", "==", _userName));

    const querySnapshot = await getDocs(q);
    
    if(querySnapshot.empty){
      console.log("User with the username not found");
      return user;
    } else {
      querySnapshot.forEach((doc) => {
        //console.log(doc.id, " => ", doc.data());
        if(doc.data())
        user = doc.data() as User;
      })

    } 
  }catch (e) {
    console.error(
      `${e} Occurred During User Query for User with userName:${_userName}`
    );
    throw e;
  }
  return user;
}

export { getUserByEmail };
export { getUserById };
export { getUserByUserName };
import React from "react";
import "../css/login.css";
import { useState } from "react";
import { login } from "../../User-Handling/login.ts";
import { createUser } from "../../User-Handling/createUser.ts";
import { getUserByEmail } from "../../Database/getUser.ts";
import { Container, Row, Col } from "react-bootstrap";
import { FaRegBell } from "react-icons/fa";

const Login = ({
  setUserID,
  setUserEmail,
  state,
  changeVis,
  setPage,
  newUser,
  setNewUser,
}) => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [verify, setVerify] = useState(true);
  const [confirmPassword, setConfirmPassword] = useState(false);
  const [interests, setInterests] = useState([
    { id: "Bioinformatics", set: false },
    { id: "Cybersecurity", set: false },
    { id: "Databases", set: false },
    { id: "Digital Humanities", set: false },
    { id: "Gaming", set: false },
    { id: "Human-Computer Interactions", set: false },
    { id: "Machine Learning", set: false },
    { id: "Mobile Development", set: false },
    { id: "Networking", set: false },
    { id: "Robotics", set: false },
    { id: "Visualization", set: false },
    { id: "Web Development", set: false },
    { id: "Other", set: false },
  ]);
  const [qualifications, setQualifications] = useState([
    { id: "CSCE 110", subheader: "Programming I", set: false },
    { id: "CSCE 111", subheader: "Introduction to Programming", set: false },
    { id: "CSCE 120", subheader: "Program Design", set: false },
    { id: "CSCE 121", subheader: "Introduction to Program Design", set: false },
    { id: "CSCE 181", subheader: "Introduction to Computing", set: false },
    { id: "CSCE 201", subheader: "Fundamentals of Cybersecurity", set: false },
    { id: "CSCE 206", subheader: "Structured Programming in C", set: false },
    { id: "CSCE 221", subheader: "Data Structures and Algorithms", set: false },
    {
      id: "CSCE 222",
      subheader: "Discrete Structures for Computing",
      set: false,
    },
    { id: "CSCE 305", subheader: "Computational Data Science", set: false },
    { id: "CSCE 310", subheader: "Database Systems", set: false },
    { id: "CSCE 312", subheader: "Computer Organization", set: false },
    {
      id: "CSCE 313",
      subheader: "Introduction to Computer Systems",
      set: false,
    },
    { id: "CSCE 314", subheader: "Programming Languages", set: false },
    { id: "CSCE 315", subheader: "Programming Studio", set: false },
    { id: "CSCE 320", subheader: "Principles of Data Science", set: false },
    {
      id: "CSCE 331",
      subheader: "Foundations of Software Engineering",
      set: false,
    },
    {
      id: "CSCE 350",
      subheader: "Computer Architecture and Design",
      set: false,
    },
    {
      id: "CSCE 402",
      subheader: "Law and Policy in Cybersecurity",
      set: false,
    },
    { id: "CSCE 410", subheader: "Operating Systems", set: false },
    {
      id: "CSCE 411",
      subheader: "Design and Analysis of Algorithms",
      set: false,
    },
    { id: "CSCE 412", subheader: "Cloud Computing", set: false },
    { id: "CSCE 413", subheader: "Software Security", set: false },
    { id: "CSCE 416", subheader: "Hardware Design Verification", set: false },
    { id: "CSCE 420", subheader: "Artificial Intelligence", set: false },
    { id: "CSCE 421", subheader: "Machine Learning", set: false },
    { id: "CSCE 426", subheader: "Security of Embedded Systems", set: false },
    {
      id: "CSCE 430",
      subheader: "Problem Solving Programming Strategies",
      set: false,
    },
    { id: "CSCE 431", subheader: "Software Engineering", set: false },
    { id: "CSCE 433", subheader: "Formal Languages and Automata", set: false },
    { id: "CSCE 434", subheader: "Compiler Design", set: false },
    { id: "CSCE 435", subheader: "Parallel Computing", set: false },
    { id: "CSCE 436", subheader: "Computer-Human Interaction", set: false },
    { id: "CSCE 438", subheader: "Distributed Systems", set: false },
    { id: "CSCE 440", subheader: "Quantum Algorithms", set: false },
    { id: "CSCE 441", subheader: "Computer Graphics", set: false },
    { id: "CSCE 442", subheader: "Scientific Programming", set: false },
    { id: "CSCE 443", subheader: "Game Development", set: false },
    {
      id: "CSCE 444",
      subheader: "Structures of Interactive Information",
      set: false,
    },
    { id: "CSCE 445", subheader: "Computers and New Media", set: false },
    { id: "CSCE 446", subheader: "Virtual Reality", set: false },
    { id: "CSCE 447", subheader: "Data Visualization", set: false },
    { id: "CSCE 449", subheader: "Applied Cryptography", set: false },
    { id: "CSCE 451", subheader: "Software Reverse Engineering", set: false },
    {
      id: "CSCE 452",
      subheader: "Robotics and Spatial Intelligence",
      set: false,
    },
    { id: "CSCE 456", subheader: "Real-Time Computing", set: false },
    {
      id: "CSCE 461",
      subheader: "Embedded Systems for Medical Applications",
      set: false,
    },
    { id: "CSCE 462", subheader: "Microcomputer Systems", set: false },
    {
      id: "CSCE 463",
      subheader: "Networks and Distributed Processing",
      set: false,
    },
    { id: "CSCE 464", subheader: "Wireless and Mobile Systems", set: false },
    { id: "CSCE 465", subheader: "Computer and Network Security", set: false },
    { id: "CSCE 469", subheader: "Advanced Computer Architecture", set: false },
    {
      id: "CSCE 470",
      subheader: "Information Storage and Retrieval",
      set: false,
    },
    { id: "CSCE 477", subheader: "Cybersecurity Risk", set: false },
    { id: "CSCE 482", subheader: "Senior Capstone Design", set: false },
    { id: "CSCE 483", subheader: "Computer Systems Design", set: false },
  ]);

  const changeID = (id) => {
    setUserID(id);
  };

  let user;

  const handleLogin = async (userEmail, userPassword) => {
    if (await login(userEmail, userPassword)) {
      changeVis(!state);
      setVerify(true);
      user = await getUserByEmail(userEmail);
      if (user) {
        console.log(email);
        console.log(user.userId);
        setUserID(user.userId);
        setUserEmail(email);
        localStorage.setItem("userID", user.userId);
        localStorage.setItem("page", 1);
        setPage(1);
      }
    } else {
      setVerify(false);
    }
  };

  const handleCreateUser = async (
    firstName,
    lastName,
    username,
    email,
    password,
    interests,
    qualifications
  ) => {
    if (
      await createUser(
        firstName,
        lastName,
        username,
        email,
        password,
        interests,
        qualifications
      )
    ) {
      changeVis(!state);
      setVerify(true);
      setPage(1);
    }
  };

  const handleInterests = (id, set) => {
    setInterests(
      interests.map((intr) => {
        if (id === intr.id) {
          return { id: intr.id, set: set };
        } else return { id: intr.id, set: intr.set };
      })
    );
  };

  const handleQualifications = (id, set) => {
    setQualifications(
      qualifications.map((qual) => {
        if (id === qual.id) {
          return { id: qual.id, set: set };
        } else return { id: qual.id, set: qual.set };
      })
    );
  };

  let UserLogin = (
    <Container className="loginBody">
      <Row>
        <Col>
          <h1>Howdy!</h1>
          {!verify && (
            <p style={{ color: "red" }}>Email or password is incorrect</p>
          )}
          <input
            placeholder="email@tamu.edu"
            onChange={(event) => setEmail(event.target.value)}
          ></input>
          <input
            type="password"
            placeholder="password"
            onChange={(event) => setPassword(event.target.value)}
          ></input>
          <button
            onClick={() => {
              handleLogin(email, password);
            }}
          >
            Login
          </button>
        </Col>
      </Row>

      <p>or</p>
      <span
        onClick={() => {
          setNewUser(!newUser);
        }}
      >
        Create Account
      </span>
    </Container>
  );

  let CreateUser = (
    <Container className="createUserBody">
      <h1 style={{ alignSelf: "center", marginBottom: "20px" }}>Sign Up</h1>
      <Col>
        <Row>
          <Col>
            <input
              className="createUserInput"
              placeholder="First Name"
              onChange={(event) => setFirstName(event.target.value)}
            ></input>
          </Col>
          <Col>
            <input
              className="createUserInput"
              placeholder="Last Name"
              onChange={(event) => setLastName(event.target.value)}
            ></input>
          </Col>
        </Row>
        <Row>
          <Col>
            <input
              className="createUserInput"
              placeholder="username"
              onChange={(event) => setUsername(event.target.value)}
            ></input>
            <input
              className="createUserInput"
              placeholder="email@tamu.edu"
              onChange={(event) => setEmail(event.target.value)}
            ></input>
            <input
              className="createUserInput"
              type="password"
              placeholder="password"
              onChange={(event) => setPassword(event.target.value)}
            ></input>
            <h7 style={{ color: "red" }}>
              {verify ? "" : "Passwords do not match, try again."}
            </h7>
            <input
              className="createUserInput"
              type="password"
              placeholder="confirm password"
              onChange={(event) => setConfirmPassword(event.target.value)}
            ></input>
            <p style={{ fontWeight: "600", fontSize: "max(0.8vw, 0.6rem)" }}>
              Interests
            </p>
            <Row className="box">
              {interests.map((intr) => {
                return (
                  <Col
                    xs="auto"
                    className="tag"
                    id={intr.set ? "selected" : "notset"}
                    onClick={() => {
                      handleInterests(intr.id, !intr.set);
                    }}
                  >
                    {intr.id}
                  </Col>
                );
              })}
            </Row>
            <p style={{ fontWeight: "600", fontSize: "max(0.8vw, 0.6rem)" }}>
              Qualifications
            </p>
            <Row className="box">
              {qualifications.map((qual) => {
                return (
                  <Col
                    xs="auto"
                    className="tag"
                    id={qual.set ? "selected" : "notset"}
                    title={qual.subheader}
                    onClick={() => {
                      handleQualifications(qual.id, !qual.set);
                    }}
                  >
                    {qual.id}
                  </Col>
                );
              })}
            </Row>
            <Row style={{ justifyContent: "space-evenly" }}>
              <button
                className="createButton"
                onClick={() => {
                  if (password === confirmPassword)
                    handleCreateUser(
                      firstName,
                      lastName,
                      username,
                      email,
                      password,
                      interests,
                      qualifications
                    );
                  else setVerify(false);
                }}
              >
                Create
              </button>
            </Row>
          </Col>
        </Row>
      </Col>
    </Container>
  );

  return newUser ? CreateUser : UserLogin;
};

export default Login;

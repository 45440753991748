import React from "react";
import "../css/modal.css";
import { motion } from "framer-motion";
import { useState } from "react";
import Inbox from "./Inbox";

const InboxModal = ({ userID, state, changeVis }) => {
  const show = {
    visiblility: "visible",
  };

  const show1 = {
    visiblility: "visible",
    scale: 1,
  };

  return (
    <div className="inboxModal">
      <span className="inboxClose" onClick={() => changeVis(!state)}>
        &times;
      </span>
      <Inbox userID={userID} changeVis={changeVis} />
    </div>
  );
};

export default InboxModal;

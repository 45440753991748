import { getProject } from "../Database/getProject";
import { getUserById } from "../Database/getUser";
import { storeJoinRequest } from "../Database/storeJoinRequest";
import { storeUser } from "../Database/storeUser";
import { updateProject } from "../Project-Handling/updateProject";
import { Project } from "../Schema/Project";
import { JoinRequest, RequestStatus } from "../Schema/JoinRequest";
import { User } from "../Schema/User";

async function acceptRequest(request: JoinRequest) {
  try {
    // Update request status and store
    request.requestStatus = RequestStatus.ACCEPTED;
    storeJoinRequest(request);

    // Update project member list
    const project: Project = await getProject(request.projectId);
    project.members.push(request.senderUsername);
    updateProject(
      project.projectId,
      project.projectName,
      project.projectOwnerId,
      project.description,
      project.createdOn,
      project.members,
      project.tags,
      project.requirements,
      project.completionPercentage,
      project.status
    );

    // Update user's active project count
    const user: User = await getUserById(request.requestSenderId);
    user.numJoined++;
    storeUser(user);
  } catch (e) {
    console.log(
      `${e} Occurred While Adding Member ${request.requestSenderId} to Project`
    );
  }
}

export { acceptRequest };

import { db } from "../Database/getDB";
import {
  validateFirstName,
  validateLastName,
  validateUserName,
  validatePassword,
} from "../User-Handling/validateUserFields";
import { doc, updateDoc } from "@firebase/firestore";
import { getUserByEmail, getUserById, getUserByUserName } from "../Database/getUser";
import { User } from "../Schema/User";

async function updateUserNameById(
  userId: string,
  newUserName: string
): Promise<boolean> {
  if (!validateUserName(newUserName)) {
    return false;
  }

  const userCheck2: User = await getUserByUserName(newUserName);
  if (userCheck2.userName !== "") {
    console.log("Entered username has already been taken.");
    return false;
  }

  try {
    const docRef = await doc(db, "Users", userId);

    await updateDoc(docRef, {
      userName: newUserName,
    });
  } catch (e) {
    return false;
  }
  return true;
}
async function updateUserNameByEmail(
  _email: string,
  newUserName: string
): Promise<boolean> {
  const userID: string = (await getUserByEmail(_email)).userId;
  return updateUserNameById(userID, newUserName);
}

async function updatePasswordById(
  userId: string,
  newPassword: string
): Promise<boolean> {
  if (!validatePassword(newPassword)) {
    return false;
  }

  try {
    const docRef = await doc(db, "Users", userId);

    await updateDoc(docRef, {
      password: newPassword,
    });
  } catch (e) {
    return false;
  }
  return true;
}
async function updatePasswordByEmail(
  _email: string,
  newPassword: string
): Promise<boolean> {
  const userID: string = (await getUserByEmail(_email)).userId;
  return updatePasswordById(userID, newPassword);
}

async function updateFirstNameById(
  userId: string,
  newFirstName: string
): Promise<boolean> {
  if (!validateFirstName(newFirstName)) {
    return false;
  }

  try {
    const docRef = await doc(db, "Users", userId);

    await updateDoc(docRef, {
      firstName: newFirstName,
    });
  } catch (e) {
    return false;
  }
  return true;
}
async function updateFirstNameByEmail(
  _email: string,
  newFirstName: string
): Promise<boolean> {
  const userID: string = (await getUserByEmail(_email)).userId;
  return updateFirstNameById(userID, newFirstName);
}

async function updateLastNameById(
  userId: string,
  newLastName: string
): Promise<boolean> {
  if (!validateLastName(newLastName)) {
    return false;
  }

  try {
    const docRef = await doc(db, "Users", userId);

    await updateDoc(docRef, {
      lastName: newLastName,
    });
  } catch (e) {
    return false;
  }
  return true;
}
async function updateLastNameByEmail(
  _email: string,
  newLastName: string
): Promise<boolean> {
  const userID: string = (await getUserByEmail(_email)).userId;
  return updateLastNameById(userID, newLastName);
}

async function updateAvatarById(
  userId: string,
  newAvatar: any
): Promise<boolean> {
  try {
    const docRef = await doc(db, "Users", userId);

    await updateDoc(docRef, {
      avatar: newAvatar,
    });
  } catch (e) {
    return false;
  }
  return true;
}
async function updateAvatarByEmail(
  _email: string,
  newAvatar: any
): Promise<boolean> {
  const userID: string = (await getUserByEmail(_email)).userId;
  return updateAvatarById(userID, newAvatar);
}

async function updateQualificationsById(
  userId: string,
  newQualifications: string[]
): Promise<boolean> {
  try {
    const docRef = await doc(db, "Users", userId);

    await updateDoc(docRef, {
      qualifications: newQualifications,
    });
  } catch (e) {
    return false;
  }
  return true;
}
async function updateQualificationsByEmail(
  _email: string,
  newQualifications: string[]
): Promise<boolean> {
  const userID: string = (await getUserByEmail(_email)).userId;
  return updateQualificationsById(userID, newQualifications);
}

async function updateInterestsById(
  userId: string,
  newInterests: string[]
): Promise<boolean> {
  try {
    const docRef = await doc(db, "Users", userId);

    await updateDoc(docRef, {
      interests: newInterests,
    });
  } catch (e) {
    return false;
  }
  return true;
}
async function updateInterestsByEmail(
  _email: string,
  newInterests: string[]
): Promise<boolean> {
  const userID: string = (await getUserByEmail(_email)).userId;
  return updateInterestsById(userID, newInterests);
}

export {
  updateUserNameById,
  updateUserNameByEmail,
  updatePasswordById,
  updatePasswordByEmail,
  updateFirstNameById,
  updateFirstNameByEmail,
  updateLastNameById,
  updateLastNameByEmail,
  updateInterestsById,
  updateInterestsByEmail,
  updateQualificationsById,
  updateQualificationsByEmail,
};

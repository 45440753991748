import React from "react";
import "../css/modal.css";
import { motion, useAnimation } from "framer-motion";
import { useState } from "react";
import ProjectViewer from "./ProjectViewer";

const ProjectViewerModal = ({
  Proj,
  state,
  changeVis,
  page,
  isUserOwned,
  userID,
  userEmail,
}) => {
  const show = {
    visiblility: "visible",
  };

  const show1 = {
    visiblility: "visible",
    scale: 1,
  };

  return (
    <motion.div className="baseModal" animate={show}>
      <span className="close" onClick={() => changeVis(!state)}>
        &times;
      </span>
      <motion.div className="frameModal">
        <motion.div
          className="modal"
          animate={show1}
          initial={{
            scale: 0.5,
          }}
        >
          <ProjectViewer
            Proj={Proj}
            state={state}
            changeVis={changeVis}
            page={page}
            isUserOwned={isUserOwned}
            userID={userID}
            userEmail={userEmail}
          />
        </motion.div>
      </motion.div>
    </motion.div>
  );
};

export default ProjectViewerModal;

import { doc, deleteDoc } from "firebase/firestore";
import { db } from "../Database/getDB";
import { getRequestByUserAndProject } from "../Database/getRequest";
import { JoinRequest } from "../Schema/JoinRequest";

async function deleteProjectMemberRequest(projectId: string, user: string) {
  try {
    const requests: JoinRequest[] = await getRequestByUserAndProject(
      projectId,
      user
    );
    for await (const element of requests) {
      await deleteDoc(doc(db, "Requests", element.requestId));
    }
  } catch (e) {
    console.error(
      `${e} Occurred While Deleting Project Member Requests for User: ${user}`
    );
  }
}

export { deleteProjectMemberRequest };

import React from "react";
import "../css/body.css";
import { useState, useRef } from "react";
import { motion, useAnimation } from "framer-motion";
import "../css/nav.css";
import { FaBars } from "react-icons/fa";
import { FiLogIn } from "react-icons/fi";
import { BsQuestionSquare } from "react-icons/bs";
import { RiContrast2Fill } from "react-icons/ri";
import { ImBrightnessContrast } from "react-icons/im";
import { FaSearch } from "react-icons/fa";
import { BsTags } from "react-icons/bs";

const Header = ({
  setSS,
  setSearching,
  state,
  changer,
  projectTags,
  tagChange,
  changeLIState,
  page,
  setPage,
  setContrastMode,
  contrastMode,
}) => {
  const [toggle, setToggle] = useState(true);
  const [tgSearch, setTgs] = useState(false);

  const [str, setStr] = useState("");

  const baseStyle = {
    background: "#444",
    height: "7rem",
    width: "100%",
    color: "#aaa",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "relative",
  };

  const baseStyleNav = {
    background: "#444",
    height: "3rem",
    width: "20vw",
    color: "#aaa",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    paddingRight: "1%",
    marginLeft: "10%",
  };

  const baseStyleSearch = {
    background: "#444",
    height: "3rem",
    width: "50%",
    color: "#aaa",
    display: "flex",
    justifyContent: "left",
    alignItems: "center",
    marginRight: "10%",
  };
  const baseStyleSearch1 = {
    background: "transparent",
    height: "3rem",
    color: "#aaa",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  };

  const vary = {
    stretch: {
      height: "20rem",
    },
    none: {},
  };

  const animateButton = {
    animateOut: (custom) => ({
      x: custom,
      opacity: 1,
    }),
    animateIn: {
      x: 0,
    },
    rollOut: (custom) => ({
      x: custom,
      rotate: 90,
      scale: 1.75,
    }),
    hoverEffect: {
      cursor: "pointer",
      background: "#555",
      transition: ".5s",
    },
  };

  const animateSearch = {
    animateTo: (custom) => ({
      x: custom,
    }),
    animateBack: {
      x: 0,
    },
    animatedHid: {
      opacity: 0,
      scale: 0,
      width: "0px",
      transition: {
        stiffness: 10,
      },
    },
    animatedShow: {
      opacity: 1,
      scale: 1,
      transition: {
        stiffness: 10,
        when: "beforeChildren",
        staggerChildren: 0.1,
      },
    },
    animatedShowButton: {
      opacity: 1,
      scale: 1,
      transition: {
        stiffness: 10,
      },
    },
    props1: {
      opacity: 1,
      width: "60vw",
      scale: 1,
      transition: {
        stiffness: 10,
        when: "beforeChildren",
        staggerChildren: 0.1,
      },
    },
    props2: {
      opacity: 0,
      width: "0vw",
      transition: { duration: 0 },
    },
  };

  const searchControl = useAnimation();
  const searchControl1 = useAnimation();

  const sequence = async () => {
    if (state) {
      await searchControl1.start({
        opacity: 0,
        scale: 0,
        width: "0px",
        transition: {
          stiffness: 10,
        },
      });
      return await searchControl.start({
        opacity: 1,
        scale: 1,
        width: "60vw",
        transition: {
          duration: 0,
        },
      });
    } else {
      await searchControl.start({
        opacity: 0,
        width: "0rem",
        transition: {
          stiffness: 10,
        },
      });
      return await searchControl1.start("animatedShow");
    }
  };

  const changePageColor = (mode) => {
    if (mode) {
      document.documentElement.setAttribute("theme", "HC");
      localStorage.setItem("siteTheme", "HC");
    } else {
      document.documentElement.setAttribute("theme", "default");
      localStorage.setItem("siteTheme", "default");
    }
  };

  return (
    <div>
      <motion.div
        variants={vary}
        animate={state ? "stretch" : "none"}
        style={baseStyle}
      >
        <motion.div style={baseStyleSearch}>
          <motion.div
            style={baseStyleSearch1}
            animate={searchControl}
            initial={{
              width: "60vw",
            }}
          >
            <motion.input
              className="searchinput"
              variants={animateSearch}
              custom={"1vw"}
              placeholder="Search by project owner..."
              onChange={(event) => {
                if (event.target.value == "") {
                  setSearching(false);
                } else setStr(event.target.value);
              }}
            ></motion.input>
            <motion.button
              variants={animateSearch}
              className="iconbutton"
              title="Search"
              onClick={() => {
                if (str != "") {
                  setSS(str);
                  setSearching(true);
                }
              }}
            >
              <FaSearch />
            </motion.button>
          </motion.div>
          <motion.button
            variants={animateSearch}
            className="iconbutton"
            animate={"animatedShow"}
            onClick={() => {
              setTgs(!tgSearch);
              changer(!state);
              sequence();
            }}
            title="Tag Search"
          >
            <BsTags />
          </motion.button>

          <motion.div
            variants={animateSearch}
            className="grid"
            animate={tgSearch ? animateSearch.props1 : animateSearch.props2}
            initial={{
              opacity: 0,
              scale: 0,
              width: "0rem",
            }}
          >
            {projectTags.map((tag) => {
              return (
                <motion.div
                  className="tag"
                  variants={animateSearch}
                  id={tag.set ? "set" : "notset"}
                  onClick={() => {
                    tagChange(tag.id, !tag.set);
                  }}
                >
                  {tag.id}
                </motion.div>
              );
            })}
          </motion.div>
        </motion.div>

        <motion.div variants={vary} style={baseStyleNav}>
          <motion.button
            variants={animateButton}
            animate={toggle ? "animateOut" : "animateIn"}
            custom={"-3vw"}
            className="iconbuttonc"
            title="Contrast Button"
            onClick={() => {
              setContrastMode(!contrastMode);
              changePageColor(contrastMode);
            }}
          >
            {!contrastMode ? <ImBrightnessContrast /> : <RiContrast2Fill />}
          </motion.button>

          <motion.button
            variants={animateButton}
            animate={toggle ? "animateOut" : "animateIn"}
            custom={"-2vw"}
            className="iconbuttonc"
            title="About Us"
          >
            <BsQuestionSquare />
          </motion.button>

          <motion.button
            variants={animateButton}
            animate={toggle ? "animateOut" : "animateIn"}
            custom={"-1vw"}
            className="iconbuttonc"
            title="Log In"
            onClick={() => changeLIState(true)}
          >
            <FiLogIn />
          </motion.button>

          <motion.button
            variants={animateButton}
            animate={toggle ? "rollOut" : "animateIn"}
            custom={"0vw"}
            onClick={() => setToggle(!toggle)}
            className="iconbuttonr"
            title="Toggle"
          >
            <FaBars />
          </motion.button>
        </motion.div>
      </motion.div>
    </div>
  );
};

export default Header;

import { doc, getDoc } from "@firebase/firestore";
import { db } from "../Database/getDB";
import { Project } from "../Schema/Project";

async function getProject(projectId: string): Promise<Project> {
  let project: Project;
  try {
    // const projectCollection = db.collection("Projects").doc(projectId);
    const docRef = await doc(db, "Projects", projectId);
    const snapshot = await getDoc(docRef);
    if (snapshot.exists()) {
      project = snapshot.data() as Project;
    } else {
      throw Error("Project does not exist");
    }
  } catch (e) {
    console.error(
      `${e} Occurred During Project Query for Project:${projectId}`
    );
    throw e;
  }

  return project;
}

export { getProject };

import UserPage from "./Components/UserComponents/UserPage";
import GuestPage from "./Components/GuestComponents/GuestPage";
import UserProfilePage from "./Components/UserComponents/UserProfile";
import { useState } from "react";

function App() {
  const [userID, setUserID] = useState("");

  const [userEmail, setUserEmail] = useState("");

  const [contrastMode, setContrastMode] = useState(true);

  const [page, setPage] = useState(0);

  const [loaded, setLoaded] = useState(false);

  let pageViewed;

  const onLoadColor = () => {
    const currentTheme = localStorage.getItem("siteTheme");
    if (currentTheme) {
      document.documentElement.setAttribute("theme", currentTheme);

      if (currentTheme === "HC") {
        setContrastMode(false);
      } else {
        setContrastMode(true);
      }
    } else {
      document.documentElement.setAttribute("theme", "default");
      localStorage.setItem("siteTheme", "default");
      setContrastMode(true);
    }
  };
  const onLoad = () => {
    const currentPage = localStorage.getItem("page");
    console.log("CP " + currentPage);
    if (currentPage) {
      if (currentPage == 1) {
        const currentUser = localStorage.getItem("userID");
        if (currentUser) {
          setUserID(currentUser);
          setPage(1);
        } else {
          setPage(0);
        }
      } else {
        setPage(0);
      }
    } else {
      localStorage.setItem("page", 0);
      setPage(0);
    }
  };
  if (!loaded) {
    onLoadColor();
    onLoad();
    setLoaded(true);
  }

  const pageLoad = () => {
    if (page == 0) {
      pageViewed = (
        <GuestPage
          setUserID={setUserID}
          setUserEmail={setUserEmail}
          page={page}
          setPage={setPage}
          setContrastMode={setContrastMode}
          contrastMode={contrastMode}
        />
      );
    } else if (page == 1) {
      pageViewed = (
        <UserPage
          userID={userID}
          userEmail={userEmail}
          page={page}
          setPage={setPage}
          setContrastMode={setContrastMode}
          contrastMode={contrastMode}
        />
      );
    }
  };

  console.log(userID);
  console.log(userEmail);
  pageLoad();

  return <>{pageViewed}</>;
}

export default App;

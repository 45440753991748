import React from "react";
import "../css/createproject.css";
import { motion, useAnimation } from "framer-motion";
import { useState } from "react";
import { createProject } from "../../Project-Handling/createProject.ts";
import { getUserByEmail } from "../../Database/getUser.ts";
import { Container, Row, Col, ProgressBar } from "react-bootstrap";

const CreateProject = ({ state, changeVis, userID }) => {
  const [projectTitle, setPT] = useState("");
  const [projectDescription, setPD] = useState("");
  const [progressBar, setPB] = useState(0);

  const [memberUN, setMemberUN] = useState("");

  const [members, setMembers] = useState([]);

  const [tags, setCreationTags] = useState([
    { id: "Bioinformatics", set: false },
    { id: "Cybersecurity", set: false },
    { id: "Databases", set: false },
    { id: "Digital Humanities", set: false },
    { id: "Gaming", set: false },
    { id: "Human-Computer Interactions", set: false },
    { id: "Machine Learning", set: false },
    { id: "Mobile Development", set: false },
    { id: "Networking", set: false },
    { id: "Robotics", set: false },
    { id: "Visualization", set: false },
    { id: "Web Development", set: false },
    { id: "Other", set: false },
  ]);

  const [reqs, setCreationReqs] = useState([
    { id: "CSCE 110", subheader: "Programming I", set: false },
    { id: "CSCE 111", subheader: "Introduction to Programming", set: false },
    { id: "CSCE 120", subheader: "Program Design", set: false },
    { id: "CSCE 121", subheader: "Introduction to Program Design", set: false },
    { id: "CSCE 181", subheader: "Introduction to Computing", set: false },
    { id: "CSCE 201", subheader: "Fundamentals of Cybersecurity", set: false },
    { id: "CSCE 206", subheader: "Structured Programming in C", set: false },
    { id: "CSCE 221", subheader: "Data Structures and Algorithms", set: false },
    {
      id: "CSCE 222",
      subheader: "Discrete Structures for Computing",
      set: false,
    },
    { id: "CSCE 305", subheader: "Computational Data Science", set: false },
    { id: "CSCE 310", subheader: "Database Systems", set: false },
    { id: "CSCE 312", subheader: "Computer Organization", set: false },
    {
      id: "CSCE 313",
      subheader: "Introduction to Computer Systems",
      set: false,
    },
    { id: "CSCE 314", subheader: "Programming Languages", set: false },
    { id: "CSCE 315", subheader: "Programming Studio", set: false },
    { id: "CSCE 320", subheader: "Principles of Data Science", set: false },
    {
      id: "CSCE 331",
      subheader: "Foundations of Software Engineering",
      set: false,
    },
    {
      id: "CSCE 350",
      subheader: "Computer Architecture and Design",
      set: false,
    },
    {
      id: "CSCE 402",
      subheader: "Law and Policy in Cybersecurity",
      set: false,
    },
    { id: "CSCE 410", subheader: "Operating Systems", set: false },
    {
      id: "CSCE 411",
      subheader: "Design and Analysis of Algorithms",
      set: false,
    },
    { id: "CSCE 412", subheader: "Cloud Computing", set: false },
    { id: "CSCE 413", subheader: "Software Security", set: false },
    { id: "CSCE 416", subheader: "Hardware Design Verification", set: false },
    { id: "CSCE 420", subheader: "Artificial Intelligence", set: false },
    { id: "CSCE 421", subheader: "Machine Learning", set: false },
    { id: "CSCE 426", subheader: "Security of Embedded Systems", set: false },
    {
      id: "CSCE 430",
      subheader: "Problem Solving Programming Strategies",
      set: false,
    },
    { id: "CSCE 431", subheader: "Software Engineering", set: false },
    { id: "CSCE 433", subheader: "Formal Languages and Automata", set: false },
    { id: "CSCE 434", subheader: "Compiler Design", set: false },
    { id: "CSCE 435", subheader: "Parallel Computing", set: false },
    { id: "CSCE 436", subheader: "Computer-Human Interaction", set: false },
    { id: "CSCE 438", subheader: "Distributed Systems", set: false },
    { id: "CSCE 440", subheader: "Quantum Algorithms", set: false },
    { id: "CSCE 441", subheader: "Computer Graphics", set: false },
    { id: "CSCE 442", subheader: "Scientific Programming", set: false },
    { id: "CSCE 443", subheader: "Game Development", set: false },
    {
      id: "CSCE 444",
      subheader: "Structures of Interactive Information",
      set: false,
    },
    { id: "CSCE 445", subheader: "Computers and New Media", set: false },
    { id: "CSCE 446", subheader: "Virtual Reality", set: false },
    { id: "CSCE 447", subheader: "Data Visualization", set: false },
    { id: "CSCE 449", subheader: "Applied Cryptography", set: false },
    { id: "CSCE 451", subheader: "Software Reverse Engineering", set: false },
    {
      id: "CSCE 452",
      subheader: "Robotics and Spatial Intelligence",
      set: false,
    },
    { id: "CSCE 456", subheader: "Real-Time Computing", set: false },
    {
      id: "CSCE 461",
      subheader: "Embedded Systems for Medical Applications",
      set: false,
    },
    { id: "CSCE 462", subheader: "Microcomputer Systems", set: false },
    {
      id: "CSCE 463",
      subheader: "Networks and Distributed Processing",
      set: false,
    },
    { id: "CSCE 464", subheader: "Wireless and Mobile Systems", set: false },
    { id: "CSCE 465", subheader: "Computer and Network Security", set: false },
    { id: "CSCE 469", subheader: "Advanced Computer Architecture", set: false },
    {
      id: "CSCE 470",
      subheader: "Information Storage and Retrieval",
      set: false,
    },
    { id: "CSCE 477", subheader: "Cybersecurity Risk", set: false },
    { id: "CSCE 482", subheader: "Senior Capstone Design", set: false },
    { id: "CSCE 483", subheader: "Computer Systems Design", set: false },
  ]);

  const setTags = (id, set) => {
    setCreationTags(
      tags.map((tag) => {
        if (id === tag.id) {
          return { id: tag.id, set: set };
        } else return { id: tag.id, set: tag.set };
      })
    );
  };

  const setReqs = (id, set) => {
    setCreationReqs(
      reqs.map((req) => {
        if (id === req.id) {
          return { id: req.id, set: set };
        } else return { id: req.id, set: req.set };
      })
    );
  };

  const handleSubmit = async () => {
    if (userID)
      await createProject(
        projectTitle,
        userID,
        projectDescription,
        tags
          .filter((tag) => {
            if (tag.set) {
              return true;
            }
          })
          .map((tag) => {
            return tag.id;
          }),
        reqs
          .filter((req) => {
            if (req.set) {
              return req.id;
            }
          })
          .map((req) => {
            return req.id;
          }),
        progressBar
      );
  };

  console.log(userID);

  let isreal;

  const addMember = async () => {
    isreal = await getUserByEmail(memberUN);

    if (isreal.userId != "" && isreal.userId != userID) {
      if (!members.includes(memberUN)) setMembers([...members, memberUN]);
    } else {
    }
  };

  const removeMember = async (member) => {
    setMembers(
      members.filter((name) => {
        if (member != name) return true;
      })
    );
  };

  return (
    <Container className="createProjectBody">
      <Row style={{ justifyContent: "space-evenly" }}>
        <input
          className="titleField"
          onChange={(event) => setPT(event.target.value)}
          placeholder="Project Title"
        ></input>
      </Row>
      <Row>
        <h1>Description</h1>
        <textarea
          className="descriptionField"
          onChange={(event) => setPD(event.target.value)}
          placeholder="Enter a brief project description..."
        ></textarea>
      </Row>
      <Row>
        <Col>
          <h1>Requirements</h1>
          <Row className="box">
            {reqs.map((req) => {
              return (
                <Col
                  className="tag"
                  id={req.set ? "selected" : "notset"}
                  title={req.subheader}
                  onClick={() => {
                    setReqs(req.id, !req.set);
                  }}
                >
                  {req.id}
                </Col>
              );
            })}
          </Row>
          <h1>Tags</h1>
          <Row className="box">
            {tags.map((tag) => {
              return (
                <Col
                  className="tag"
                  id={tag.set ? "selected" : "notset"}
                  onClick={() => {
                    setTags(tag.id, !tag.set);
                  }}
                >
                  {tag.id}
                </Col>
              );
            })}
          </Row>
          <h1>Members</h1>
          <Row className="justify-content-center box">
            {members.length == 0
              ? "No other members."
              : members.map((member) => {
                  return (
                    <li
                      onClick={() => {
                        removeMember(member);
                      }}
                      className="memberName"
                    >
                      {member}
                    </li>
                  );
                })}

            <input
              className="membersField"
              placeholder="Enter member emails..."
              style={{ margin: "2px", marginTop: "5px" }}
              onChange={(event) => {
                setMemberUN(event.target.value);
              }}
            ></input>
            <button
              className="enter"
              onClick={() => {
                addMember();
              }}
            >
              Enter
            </button>
          </Row>
          <h1>Project Progress</h1>
          <Row className="box">
            <p style={{ width: "auto", paddingLeft: "0px" }}>Set Progress:</p>
            <input
              className="progressField"
              placeholder="0"
              onChange={(event) => {
                setPB(event.target.value);
              }}
            ></input>
            <p style={{ width: "auto", padding: "2px" }}>%</p>
            <div className="progressBar">
              <div
                style={{ width: progressBar + "%" }}
                className="progress"
              ></div>
            </div>
          </Row>
        </Col>
      </Row>
      <Row style={{ justifyContent: "space-evenly" }}>
        <button
          className="createButton"
          onClick={async () => {
            await handleSubmit();
            window.location.reload();
          }}
        >
          Create
        </button>
      </Row>
    </Container>
  );
};

export default CreateProject;

"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
exports.__esModule = true;
exports.getUserByUserName = exports.getUserById = exports.getUserByEmail = void 0;
var firestore_1 = require("@firebase/firestore");
var getDB_1 = require("../Database/getDB");
function getUserById(userId) {
    return __awaiter(this, void 0, void 0, function () {
        var user, docRef, snapshot, e_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    user = {
                        userId: "",
                        userName: "",
                        password: "",
                        firstName: "",
                        lastName: "",
                        email: "",
                        interests: [],
                        qualifications: [],
                        numHosting: 0,
                        numJoined: 0,
                        avatar: "no avatar"
                    };
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 4, , 5]);
                    return [4 /*yield*/, firestore_1.doc(getDB_1.db, "Users", userId)];
                case 2:
                    docRef = _a.sent();
                    return [4 /*yield*/, firestore_1.getDoc(docRef)];
                case 3:
                    snapshot = _a.sent();
                    if (snapshot.exists()) {
                        user = snapshot.data();
                    }
                    else {
                        console.log("User with the id not found");
                        return [2 /*return*/, user];
                    }
                    return [3 /*break*/, 5];
                case 4:
                    e_1 = _a.sent();
                    console.error(e_1 + " Occurred During User Query for User:" + userId);
                    throw e_1;
                case 5: return [2 /*return*/, user];
            }
        });
    });
}
exports.getUserById = getUserById;
function getUserByEmail(_email) {
    return __awaiter(this, void 0, void 0, function () {
        var user, q, querySnapshot, e_2;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    user = {
                        userId: "",
                        userName: "",
                        password: "",
                        firstName: "",
                        lastName: "",
                        email: "",
                        interests: [],
                        qualifications: [],
                        numHosting: 0,
                        numJoined: 0,
                        avatar: "no avatar"
                    };
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, , 4]);
                    q = firestore_1.query(firestore_1.collection(getDB_1.db, "Users"), firestore_1.where("email", "==", _email));
                    return [4 /*yield*/, firestore_1.getDocs(q)];
                case 2:
                    querySnapshot = _a.sent();
                    if (querySnapshot.empty) {
                        console.log("User with the email not found");
                        return [2 /*return*/, user];
                    }
                    else {
                        querySnapshot.forEach(function (doc) {
                            //console.log(doc.id, " => ", doc.data());
                            if (doc.data())
                                user = doc.data();
                        });
                    }
                    return [3 /*break*/, 4];
                case 3:
                    e_2 = _a.sent();
                    console.error(e_2 + " Occurred During User Query for User with email:" + _email);
                    throw e_2;
                case 4: return [2 /*return*/, user];
            }
        });
    });
}
exports.getUserByEmail = getUserByEmail;
function getUserByUserName(_userName) {
    return __awaiter(this, void 0, void 0, function () {
        var user, q, querySnapshot, e_3;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    user = {
                        userId: "",
                        userName: "",
                        password: "",
                        firstName: "",
                        lastName: "",
                        email: "",
                        interests: [],
                        qualifications: [],
                        numHosting: 0,
                        numJoined: 0,
                        avatar: "no avatar"
                    };
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, , 4]);
                    q = (0, firestore_1.query)((0, firestore_1.collection)(getDB_1.db, "Users"), (0, firestore_1.where)("userName", "==", _userName));
                    return [4 /*yield*/, (0, firestore_1.getDocs)(q)];
                case 2:
                    querySnapshot = _a.sent();
                    if (querySnapshot.empty) {
                        console.log("User with the username not found");
                        return [2 /*return*/, user];
                    }
                    else {
                        querySnapshot.forEach(function (doc) {
                            //console.log(doc.id, " => ", doc.data());
                            if (doc.data())
                                user = doc.data();
                        });
                    }
                    return [3 /*break*/, 4];
                case 3:
                    e_3 = _a.sent();
                    console.error(e_3 + " Occurred During User Query for User with userName:" + _userName);
                    throw e_3;
                case 4: return [2 /*return*/, user];
            }
        });
    });
}
exports.getUserByUserName = getUserByUserName;

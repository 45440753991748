import Header from "./Header";
import Body from "./Body";
import ProjectViewerModal from "../CommonComponents/ProjectViewerModal";
import LoginModal from "./LoginModal";
import "../css/app.css";
import { useState } from "react";

const GuestPage = ({
  setUserID,
  setUserEmail,
  page,
  setPage,
  setContrastMode,
  contrastMode,
}) => {
  const [toggle, setToggle] = useState(false);
  const [visiblePV, setVisiblePV] = useState(false);
  const [visibleLI, setVisibleLI] = useState(false);

  const [searching, setSearching] = useState(false);
  const [searchString, setSS] = useState("");

  const [isUserOwned, setIsUserOwned] = useState(false);

  const [project, setProject] = useState({
    projectName: "",
    projectOwnerId: "",
    description: "",
    completionPercentage: 0,
    members: [],
    tags: [],
    requirements: [],
    createdOn: "",
    lastUpdated: "",
    status: "",
  });

  const [tags, setTags] = useState([
    { id: "Bioinformatics", set: false },
    { id: "Cybersecurity", set: false },
    { id: "Databases", set: false },
    { id: "Digital Humanities", set: false },
    { id: "Gaming", set: false },
    { id: "Human-Computer Interactions", set: false },
    { id: "Machine Learning", set: false },
    { id: "Mobile Development", set: false },
    { id: "Networking", set: false },
    { id: "Robotics", set: false },
    { id: "Visualization", set: false },
    { id: "Web Development", set: false },
    { id: "Other", set: false },
  ]);

  const disableScroll = () => {
    document.body.style.overflowY = "hidden";
  };

  const enableScroll = () => {
    document.body.style.overflowY = "unset";
  };

  const handleScroll = () => {
    if (visibleLI || visiblePV) {
      disableScroll();
    } else {
      enableScroll();
    }
  };

  const changeTagState = (id, set) => {
    setTags(
      tags.map((tag) => {
        if (id === tag.id) {
          return { id: tag.id, set: set };
        } else return { id: tag.id, set: tag.set };
      })
    );
  };

  return (
    <div className="App">
      <Header
        setSS={setSS}
        setSearching={setSearching}
        state={toggle}
        changer={setToggle}
        projectTags={tags}
        tagChange={changeTagState}
        changeLIState={setVisibleLI}
        page={page}
        setPage={setPage}
        setContrastMode={setContrastMode}
        contrastMode={contrastMode}
      />
      <Body
        searching={searching}
        searchString={searchString}
        state={toggle}
        changer={setToggle}
        projectTags={tags}
        setProj={setProject}
        statePV={visiblePV}
        setPV={setVisiblePV}
        setIsUserOwned={setIsUserOwned}
      />
      {visiblePV && (
        <ProjectViewerModal
          Proj={project}
          state={visiblePV}
          changeVis={setVisiblePV}
          page={page}
          isUserOwned={isUserOwned}
        />
      )}
      {visibleLI && (
        <LoginModal
          setUserID={setUserID}
          setUserEmail={setUserEmail}
          state={visibleLI}
          changeVis={setVisibleLI}
          setPage={setPage}
        />
      )}
    </div>
  );
};

export default GuestPage;

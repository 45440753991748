import "../css/modal.css";
import "../css/login.css";
import { RiArrowGoBackLine } from "react-icons/ri";
import { useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { motion, useAnimation } from "framer-motion";
import { getUserById } from "../../Database/getUser.ts";

import {
  updateUserNameById,
  updatePasswordById,
  updateFirstNameById,
  updateLastNameById,
  updateInterestsById,
  updateQualificationsById,
} from "../../User-Handling/updateUser.ts";

const UserProfile = ({ state, changeVis, userID, page, setPage }) => {
  const [loaded, setLoaded] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const [verify, setVerify] = useState(true);
  const [confirmPassword, setConfirmPassword] = useState("");

  const [interests, setInterests] = useState([
    { id: "Bioinformatics", set: false },
    { id: "Cybersecurity", set: false },
    { id: "Databases", set: false },
    { id: "Digital Humanities", set: false },
    { id: "Gaming", set: false },
    { id: "Human-Computer Interactions", set: false },
    { id: "Machine Learning", set: false },
    { id: "Mobile Development", set: false },
    { id: "Networking", set: false },
    { id: "Robotics", set: false },
    { id: "Visualization", set: false },
    { id: "Web Development", set: false },
    { id: "Other", set: false },
  ]);
  const [qualifications, setQualifications] = useState([
    { id: "CSCE 110", subheader: "Programming I", set: false },
    { id: "CSCE 111", subheader: "Introduction to Programming", set: false },
    { id: "CSCE 120", subheader: "Program Design", set: false },
    { id: "CSCE 121", subheader: "Introduction to Program Design", set: false },
    { id: "CSCE 181", subheader: "Introduction to Computing", set: false },
    { id: "CSCE 201", subheader: "Fundamentals of Cybersecurity", set: false },
    { id: "CSCE 206", subheader: "Structured Programming in C", set: false },
    { id: "CSCE 221", subheader: "Data Structures and Algorithms", set: false },
    {
      id: "CSCE 222",
      subheader: "Discrete Structures for Computing",
      set: false,
    },
    { id: "CSCE 305", subheader: "Computational Data Science", set: false },
    { id: "CSCE 310", subheader: "Database Systems", set: false },
    { id: "CSCE 312", subheader: "Computer Organization", set: false },
    {
      id: "CSCE 313",
      subheader: "Introduction to Computer Systems",
      set: false,
    },
    { id: "CSCE 314", subheader: "Programming Languages", set: false },
    { id: "CSCE 315", subheader: "Programming Studio", set: false },
    { id: "CSCE 320", subheader: "Principles of Data Science", set: false },
    {
      id: "CSCE 331",
      subheader: "Foundations of Software Engineering",
      set: false,
    },
    {
      id: "CSCE 350",
      subheader: "Computer Architecture and Design",
      set: false,
    },
    {
      id: "CSCE 402",
      subheader: "Law and Policy in Cybersecurity",
      set: false,
    },
    { id: "CSCE 410", subheader: "Operating Systems", set: false },
    {
      id: "CSCE 411",
      subheader: "Design and Analysis of Algorithms",
      set: false,
    },
    { id: "CSCE 412", subheader: "Cloud Computing", set: false },
    { id: "CSCE 413", subheader: "Software Security", set: false },
    { id: "CSCE 416", subheader: "Hardware Design Verification", set: false },
    { id: "CSCE 420", subheader: "Artificial Intelligence", set: false },
    { id: "CSCE 421", subheader: "Machine Learning", set: false },
    { id: "CSCE 426", subheader: "Security of Embedded Systems", set: false },
    {
      id: "CSCE 430",
      subheader: "Problem Solving Programming Strategies",
      set: false,
    },
    { id: "CSCE 431", subheader: "Software Engineering", set: false },
    { id: "CSCE 433", subheader: "Formal Languages and Automata", set: false },
    { id: "CSCE 434", subheader: "Compiler Design", set: false },
    { id: "CSCE 435", subheader: "Parallel Computing", set: false },
    { id: "CSCE 436", subheader: "Computer-Human Interaction", set: false },
    { id: "CSCE 438", subheader: "Distributed Systems", set: false },
    { id: "CSCE 440", subheader: "Quantum Algorithms", set: false },
    { id: "CSCE 441", subheader: "Computer Graphics", set: false },
    { id: "CSCE 442", subheader: "Scientific Programming", set: false },
    { id: "CSCE 443", subheader: "Game Development", set: false },
    {
      id: "CSCE 444",
      subheader: "Structures of Interactive Information",
      set: false,
    },
    { id: "CSCE 445", subheader: "Computers and New Media", set: false },
    { id: "CSCE 446", subheader: "Virtual Reality", set: false },
    { id: "CSCE 447", subheader: "Data Visualization", set: false },
    { id: "CSCE 449", subheader: "Applied Cryptography", set: false },
    { id: "CSCE 451", subheader: "Software Reverse Engineering", set: false },
    {
      id: "CSCE 452",
      subheader: "Robotics and Spatial Intelligence",
      set: false,
    },
    { id: "CSCE 456", subheader: "Real-Time Computing", set: false },
    {
      id: "CSCE 461",
      subheader: "Embedded Systems for Medical Applications",
      set: false,
    },
    { id: "CSCE 462", subheader: "Microcomputer Systems", set: false },
    {
      id: "CSCE 463",
      subheader: "Networks and Distributed Processing",
      set: false,
    },
    { id: "CSCE 464", subheader: "Wireless and Mobile Systems", set: false },
    { id: "CSCE 465", subheader: "Computer and Network Security", set: false },
    { id: "CSCE 469", subheader: "Advanced Computer Architecture", set: false },
    {
      id: "CSCE 470",
      subheader: "Information Storage and Retrieval",
      set: false,
    },
    { id: "CSCE 477", subheader: "Cybersecurity Risk", set: false },
    { id: "CSCE 482", subheader: "Senior Capstone Design", set: false },
    { id: "CSCE 483", subheader: "Computer Systems Design", set: false },
  ]);
  let user;

  const loadInformation = async () => {
    user = await getUserById(userID);
    if (user) {
      setFirstName(user.firstName);
      setLastName(user.lastName);
      setUsername(user.userName);
      setPassword(user.password);
      setConfirmPassword(user.password);

      setInterests(
        interests.map((intr) => {
          if (user.interests.includes(intr.id)) {
            return { id: intr.id, set: true };
          } else return { id: intr.id, set: false };
        })
      );

      setQualifications(
        qualifications.map((qual) => {
          if (user.qualifications.includes(qual.id)) {
            return { id: qual.id, subheader: qual.subheader, set: true };
          } else return { id: qual.id, subheader: qual.subheader, set: false };
        })
      );
    }
    setLoaded(true);
  };

  if (!loaded) {
    loadInformation();
  }

  const handleInterests = (id, set) => {
    setInterests(
      interests.map((intr) => {
        if (id === intr.id) {
          return { id: intr.id, set: set };
        } else return { id: intr.id, set: intr.set };
      })
    );
  };

  const handleQualifications = (id, set) => {
    setQualifications(
      qualifications.map((qual) => {
        if (id === qual.id) {
          return { id: qual.id, set: set };
        } else return { id: qual.id, set: qual.set };
      })
    );
  };

  const handleUpdateUser = (
    username,
    password,
    firstName,
    lastName,
    interests,
    qualifications
  ) => {
    if (verify) {
    } else {
      return;
    }
    updateUserNameById(userID, username);
    updatePasswordById(userID, password);
    updateFirstNameById(userID, firstName);
    updateLastNameById(userID, lastName);
    updateInterestsById(
      userID,
      interests
        .filter((item) => item.set)
        .map((item) => {
          if (item.set) {
            return item.id;
          }
        })
    );
    updateQualificationsById(
      userID,
      qualifications
        .filter((item) => item.set)
        .map((item) => {
          if (item.set) {
            return item.id;
          }
        })
    );
  };

  const show = {
    visiblility: "visible",
  };

  const show1 = {
    visiblility: "visible",
    scale: 1,
  };

  let profilepage = (
    <Container className="createUserBody">
      <h1 style={{ alignSelf: "center", marginBottom: "20px" }}>
        Update Profile
      </h1>
      <Col>
        <Row>
          <Col>
            <input
              className="createUserInput"
              placeholder={"First Name"}
              onChange={(event) => setFirstName(event.target.value)}
              value={firstName}
            ></input>
          </Col>
          <Col>
            <input
              className="createUserInput"
              placeholder={"Last Name"}
              onChange={(event) => setLastName(event.target.value)}
              value={lastName}
            ></input>
          </Col>
        </Row>
        <Row>
          <Col>
            <input
              className="createUserInput"
              placeholder={"username"}
              onChange={(event) => setUsername(event.target.value)}
              value={username}
            ></input>
            <input
              className="createUserInput"
              type="password"
              placeholder={"password"}
              onChange={(event) => setPassword(event.target.value)}
              value={password}
            ></input>
            <h7 style={{ color: "red" }}>
              {verify ? "" : "Passwords do not match, try again."}
            </h7>
            <input
              className="createUserInput"
              type="password"
              placeholder={"password"}
              value={confirmPassword}
              onChange={(event) => setConfirmPassword(event.target.value)}
            ></input>
            <p style={{ fontWeight: "600", fontSize: "max(0.8vw, 0.6rem)" }}>
              Interests
            </p>
            <Row className="box">
              {interests.map((intr) => {
                return (
                  <Col
                    xs="auto"
                    className="tag"
                    id={intr.set ? "selected" : "notset"}
                    onClick={() => {
                      handleInterests(intr.id, !intr.set);
                    }}
                  >
                    {intr.id}
                  </Col>
                );
              })}
            </Row>
            <p style={{ fontWeight: "600", fontSize: "max(1vw, 0.5rem)" }}>
              Qualifications
            </p>
            <Row className="box">
              {qualifications.map((qual) => {
                return (
                  <Col
                    xs="auto"
                    className="tag"
                    id={qual.set ? "selected" : "notset"}
                    onClick={() => {
                      handleQualifications(qual.id, !qual.set);
                    }}
                  >
                    {qual.id}
                  </Col>
                );
              })}
            </Row>
            <Row style={{ justifyContent: "space-evenly" }}>
              <button
                className="createButton"
                onClick={() => {
                  if (password == confirmPassword) {
                    setVerify(true);
                    handleUpdateUser(
                      username,
                      password,
                      firstName,
                      lastName,
                      interests,
                      qualifications
                    );
                    changeVis(!state);
                  } else {
                    setVerify(false);
                  }
                }}
              >
                Save
              </button>
            </Row>
          </Col>
        </Row>
      </Col>
    </Container>
  );

  return (
    <motion.div className="baseModal" animate={show}>
      <span className="close" onClick={() => changeVis(!state)}>
        &times;
      </span>
      <motion.div className="frameModal">
        <motion.div
          className="modal"
          animate={show1}
          initial={{
            scale: 0.5,
          }}
        >
          {profilepage}
        </motion.div>
      </motion.div>
    </motion.div>
  );
};

export default UserProfile;

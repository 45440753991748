import React from "react";
import { useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { getRecievedRequests } from "../../Database/batchGetRequests";
import { sendAcceptEmail } from "../../Email/sendAcceptEmail";
import { sendDenialEmail } from "../../Email/sendDenialEmail";
import { acceptRequest } from "../../Request-Handling/acceptRequest";
import { deleteProjectMemberRequest } from "../../Project-Handling/deleteProjectMember";
import { getUserById } from "../../Database/getUser";
import { getProject } from "../../Database/getProject.ts";

const Inbox = ({ userID, changeVis }) => {
  const [empty, setEmpty] = useState(true);
  const [joinRequest, setJoinRequest] = useState([]);
  const [loaded, setLoaded] = useState(false);

  const [names, setNames] = useState([]);

  const loadRequests = async () => {
    let requests = await getRecievedRequests(userID);

    const projectNames = [];
    //  await requests.forEach(async (request) => {
    //    const project = await getProject(request.projectId);
    //    projectNames.push(project.projectName);
    //  });

    for await (const req of requests) {
      const project = await getProject(req.projectId);
      if (project.projectName.length > 6) {
        projectNames.push(project.projectName.substring(0, 6) + "...");
      } else projectNames.push(project.projectName);
    }

    if (requests) {
      setJoinRequest(requests);
      setEmpty(requests.length === 0);
      setNames(projectNames);
      setLoaded(true);
    } else {
      console.log("Requests undefiend");
      setLoaded(true);
    }
  };

  if (!loaded) {
    loadRequests();
  }

  let emptyInbox = (
    <Row
      style={{
        height: "80%",
        justifyContent: "center",
        alignContent: "center",
        color: "white",
      }}
    >
      No new requests.
    </Row>
  );

  let index = 0;

  let fullInbox = joinRequest.map((req) => (
    <Row
      style={{
        background: "white",
        padding: "0.8vw",
        borderRadius: "5px",
        margin: "10px",
      }}
    >
      <Row>
        <Col>
          <span style={{ width: "auto", padding: "10px", fontWeight: "bold" }}>
            From:
          </span>
          {req.senderUsername}
        </Col>
        <Col>
          <span style={{ width: "auto", padding: "10px", fontWeight: "bold" }}>
            Project:
          </span>
          {names[index++]}
        </Col>
      </Row>
      <Col xs={7} style={{ fontSize: "max(1vw, 0.8rem)" }}>
        {req.requestDescription}
      </Col>
      <Col style={{ padding: "10px" }}>
        <Row style={{ justifyContent: "center" }}>
          <button
            style={{
              margin: "2px",
              border: "0px",
              color: "white",
              background: "green",
              borderRadius: "5px",
              width: "80%",
            }}
            onClick={() => {
              acceptRequest(req);
              setLoaded(false);
            }}
          >
            Accept
          </button>
        </Row>
        <Row style={{ justifyContent: "center" }}>
          <button
            style={{
              margin: "2px",
              border: "0px",
              color: "white",
              background: "rgba(256, 0, 0)",
              borderRadius: "5px",
              width: "80%",
            }}
            onClick={() => {
              deleteProjectMemberRequest(req.projectId, req.requestSenderId);
              setLoaded(false);
            }}
          >
            Deny
          </button>
        </Row>
      </Col>
    </Row>
  ));

  return (
    <Container style={{ height: "100%" }}>
      <Row
        style={{
          height: "20%",
          borderBottom: "2px solid white",
          fontSize: "20pt",
          fontWeight: "600",
          color: "white",
          justifyContent: "center",
          padding: "0px",
          background: "rgb(60,0,0)",
        }}
      >
        Inbox
      </Row>
      {empty ? emptyInbox : fullInbox}
    </Container>
  );
};

export default Inbox;
import React from "react";
import "../css/body.css";
import { motion, useAnimation } from "framer-motion";
import { useState } from "react";

import video from "../images/12th_Dev.mp4";

import Carousel from "../CommonComponents/Carousel";

import UserCarousel from "../CommonComponents/UserCarousel";

const Body = ({
  searching,
  searchString,
  state,
  changer,
  projectTags,
  setProj,
  statePV,
  setPV,
  setIsUserOwned,
}) => {
  const moveOff1 = {
    x: 2000,
    width: "0px",
    transitionEnd: {
      display: "none",
    },
  };

  const moveOn1 = {
    x: 0,
    display: "block",
    width: "100vw",
  };

  const moveOff2 = {
    x: -2000,
    width: "0px",
    transitionEnd: {
      display: "none",
    },
  };

  const moveOn2 = {
    x: 0,
    display: "block",
    width: "100vw",
  };

  const controlerBase1 = useAnimation();
  const controlerBase2 = useAnimation();

  const logo = (
    <div id="backdrop">
      <video
        src={video}
        id="image-logo"
        autoPlay={true}
        playsInline={true}
        muted={true}
      >
        Your browser does not support the video tag.
      </video>

      <div id="blurb">
        Search for projects to join, or login to be the start of something new!
      </div>
    </div>
  );

  const result = (
    <UserCarousel
      onID={false}
      userID={searchString}
      name={searchString + "'s Projects"}
      setProj={setProj}
      statePV={statePV}
      setPV={setPV}
      setIsUserOwned={setIsUserOwned}
    />
  );

  const sequence = async () => {
    if (!state) {
      await controlerBase1.start(moveOff1);
      return await controlerBase2.start(moveOn2);
    } else {
      await controlerBase2.start(moveOff2);
      return await controlerBase1.start(moveOn1);
    }
  };

  sequence();

  let markedTag = false;

  return (
    <div className="base">
      <motion.div
        className="base1"
        animate={controlerBase1}
        intial={{
          x: 2000,
        }}
      >
        {projectTags.map((tag) => {
          if (tag.set) {
            markedTag = true;
            return (
              <Carousel
                name={tag.id}
                setProj={setProj}
                statePV={statePV}
                setPV={setPV}
                setIsUserOwned={setIsUserOwned}
              />
            );
          }
        })}

        {() => {
          if (!markedTag) {
            return (
              <div id="backdrop">
                <div>
                  Search for projects to join, or login to be the start of
                  something new!
                </div>
              </div>
            );
          }
        }}
      </motion.div>

      <motion.div className="base2" animate={controlerBase2}>
        {searching ? result : logo}
      </motion.div>
    </div>
  );
};

/*
<div id="image-logo">

</div>
*/

export default Body;
